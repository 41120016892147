<template>
    <div>
        <card-header title="Documents" icon="fa-id-card"/>
        <card-toolbar>
            <button @click="$openCard('new-document', {userId: props.userId}, card)"><i class="fas fa-plus mr-2"/>New Document</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <document-list-item
                    :card="card"
                    :doc="doc"
                    v-for="doc in documents"
                    :active="(child && child.definition.component === 'edit-document') && child.props.noteId === doc.id.toString()"
                    @click="$openCard('edit-document', {userId: props.userId, docId: doc.id}, card)"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import CardBody from "../../TIER/components/CardBody";

    import {client as http} from '../../http_client';
    import TripNoteListItem from "@/components/TripNoteListItem";
    import DocumentListItem from "@/components/DocumentListItem";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {DocumentListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                documents: []
            }
        },
        methods: {
            loadDocs: function(force) {
                this.$emit('loading', true);
                http.get('/api/users/' + this.props.userId + '/documents', {force}).then(response => {
                    this.documents = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadDocs(true);
            }
        },
        mounted() {
            this.loadDocs();
        }
    };
</script>

<style lang="scss" scoped>

</style>
